import React, {useState} from 'react';
import {cssFont, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CircularProgress} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {Trans} from 'react-i18next';
import clsx from 'clsx';
import {
    useLuniiEventTracking,
    useLuniiPage,
    useGiftCardBalanceFind,
    useGiftCardBalanceSubmit,
    useLuniiNavigation,
    useLuniiTranslation,
} from '../hooks';
import SvgGift from '../images/icons/Gift';
import SvgCheck from '../images/icons/Check';
import {convertPrice, matchLocaleWithCurrency} from '../utils';
import {Button} from '../atoms/Button';
import SvgAdd from '../images/icons/Add';
import {GiftCardField} from '../atoms/fields/GiftCardField';
import ErrorPanel from '../atoms/ErrorPanel';
import Clickable from '../Clickable';
import LegacyGiftCardModal from './LegacyGiftCardModal';
import {balance_status} from '../types';
import {Alert, Link} from '../..';

const useStyles = makeStyles((theme) => ({
    root: {},
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(4, 0),
    },
    title: {
        ...cssText(theme, 'standard', 'title_1'),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'title_2'),
            marginBottom: theme.spacing(2),
        },
    },
    balanceContent: {
        display: 'flex',
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#423F37'),
    },
    balanceAmount: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    svg: {
        marginRight: theme.spacing(1),
    },
    balance: {
        marginBottom: theme.spacing(0.5),
    },
    expiredBalance: {
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#C9310C'),
    },
    expiredBalanceAlertHightlight: {
        ...cssFont(theme, 'standard', 'secondary_body_thick'),
    },
    balanceInfo: {
        marginTop: theme.spacing(2),
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#908977'),
        [theme.breakpoints.down('sm')]: {
            ...cssText(theme, 'standard', 'caption', undefined, undefined, '#908977'),
        },
    },
    showCardMessage: {
        ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#1A9B74'),
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    balanceAvailability: {
        marginTop: theme.spacing(2),
    },
    formContainer: {
        marginTop: theme.spacing(3),
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    field: {
        flex: '1 0 10%',
        [theme.breakpoints.down('sm')]: {
            flex: 1,
        },
    },
    buttons: {
        flex: '1 1 1',
        display: 'flex',
        '& > :first-child': {
            margin: theme.spacing(0, 2),
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
            },
        },
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            flexWrap: 'wrap',
            marginTop: theme.spacing(2),
            order: 3,
            gap: theme.spacing(2, 0),
        },
    },
    warningInfos: {
        flex: '1 0 100%',
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, '#716C5E'),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            order: 2,
        },
    },
    error: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    link: {
        display: 'inline',
        ...cssText(theme, 'standard', 'button_2', undefined, undefined, 'link'),
        textDecoration: 'underline',
    },
}));

export function MyGiftCardBalance({}: MyGiftCardBalanceProps) {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {goPageByModel} = useLuniiNavigation();
    const {locale} = useLuniiPage() || {};
    const handleEventTracking = useLuniiEventTracking();
    const [showCardAddedMessage, setShowCardAddedMessage] = useState<boolean>(false); // message when balance is update
    const [balanceInfos, setBalanceInfos] = useState<balance_status | undefined>(undefined); // data from update balance call
    const [openForm, setOpenForm] = useState<boolean>(false); // form toggle state
    const {handleSubmit, register, reset, errors, watch} = useForm();
    const {
        loading: findLoading,
        error: findError,
        refetch,
    } = useGiftCardBalanceFind(setBalanceInfos);
    const handleOpenForm = () => {
        // toggle form
        if (showCardAddedMessage) setShowCardAddedMessage(false);
        setOpenForm((status: boolean) => !status);
    };
    const [onSubmit, {loading: addLoading, error: addError}] = useGiftCardBalanceSubmit((infos: balance_status) => {
        if (infos) setBalanceInfos(infos); // update balance
        handleOpenForm();
        setShowCardAddedMessage(true);
        reset();
        handleEventTracking({
            categorie: 'conversion',
            action: 'carte_cadeau',
            libelle: `${infos?.giftCardId}`,
        });
    });
    const x = {errors, register};
    const loading = addLoading || findLoading;
    const error = addError || findError;
    const handleWarningButtonClick = () => {
        goPageByModel && goPageByModel('mentions_legales');
    };
    const handleBuyGiftCardClick = () => {
        goPageByModel && goPageByModel('carte_cadeau');
    };
    const giftCardField = watch('giftCard') || '';
    function TermsComposant() {
        return (
            <Clickable
                className={classes.link}
                onClick={handleWarningButtonClick}
            >
                {t('gift_card_balance_terms')}
            </Clickable>
        );
    }
    const hasBalance = !!balanceInfos && balanceInfos.balance > 0;
    const expirationDate = balanceInfos?.expirationDate
        ? new Date(balanceInfos?.expirationDate)
        : undefined;
    return (
        <div className={classes.root}>
            <div className={classes.title}>{t('gift_card_balance_title')}</div>
            {loading && (
                <div className={classes.progress}>
                    <CircularProgress size={30} />
                </div>
            )}
            {!loading && !findError && (
                <>
                    {hasBalance && (
                        <>
                            <div className={classes.balanceContent}>
                                <SvgGift className={classes.svg} />
                                <div className={classes.balanceAmount}>
                                    <div className={classes.balance}>{`${t(
                                        'gift_card_balance_available_balance',
                                    )} : ${convertPrice(
                                        balanceInfos?.valid && balanceInfos?.balance || 0,
                                        matchLocaleWithCurrency(locale || ''),
                                    )}`}</div>
                                    {!balanceInfos?.valid && <div className={clsx(classes.balanceAmount, classes.expiredBalance)}>{`${t(
                                        'gift_card_balance_expired_balance',
                                    )} : ${convertPrice(
                                        balanceInfos?.balance,
                                        matchLocaleWithCurrency(locale || ''),
                                    )}`}</div> || false}
                                </div>
                            </div>
                            <div className={classes.balanceInfo}>
                                {t('gift_card_balance_balance_infos')}
                            </div>
                            {showCardAddedMessage && !openForm && (
                                <div className={classes.showCardMessage}>
                                    <SvgCheck className={classes.svg} />
                                    <div>{t('gift_card_balance_added_message')}</div>
                                </div>
                            )}
                            <Alert className={classes.balanceAvailability} theme='tips' description={balanceInfos.valid && (
                                <div>
                                    {t('gift_card_balance_availability')}
                                    <br />
                                    {expirationDate ? t('gift_card_balance_expiration_date', {
                                        date: new Intl.DateTimeFormat(locale).format(expirationDate)
                                    }) : ''}
                                </div>
                            ) || (
                                <Trans
                                    i18nKey='gift_card_balance_expired_warning'
                                    values={{date: new Intl.DateTimeFormat(locale).format(expirationDate)}}
                                    components={{'1': <span className={classes.expiredBalanceAlertHightlight}/>}}
                                />
                            )}/>
                        </>
                    )}
                    <div className={classes.formContainer}>
                        {!openForm && (
                            <div>
                                <Button
                                    onClick={handleOpenForm}
                                    color="plain"
                                    size="small_mobile_only"
                                    disabled={loading}
                                    startIcon={<SvgAdd />}
                                >
                                    {t('gift_card_balance_form_toggle_button')}
                                </Button>
                            </div>
                        )}
                        {openForm && (
                            <>
                                <form
                                    className={classes.form}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <GiftCardField
                                        className={classes.field}
                                        required
                                        autoFocus
                                        fullWidth
                                        {...x}
                                    />
                                    <div className={classes.buttons}>
                                        <Button
                                            type="submit"
                                            disabled={loading || giftCardField.length === 0}
                                            size="small_mobile_only"
                                            color="primary"
                                        >
                                            {t('gift_card_balance_form_add_button')}
                                        </Button>
                                        <Button
                                            size="small_mobile_only"
                                            disabled={loading}
                                            color="plain"
                                            onClick={handleOpenForm}
                                        >
                                            {t('gift_card_balance_form_cancel_button')}
                                        </Button>
                                    </div>
                                </form>
                                <div className={classes.warningInfos}>
                                    <Trans
                                        i18nKey="gift_card_balance_warning_infos"
                                        components={{devComponent: <TermsComposant />}}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            <ErrorPanel
                className={classes.error}
                error={error}
                group="giftCard"
            />
            <LegacyGiftCardModal onMergeSuccess={refetch} />
        </div>
    );
}

export interface MyGiftCardBalanceProps {}

export default MyGiftCardBalance;
